.project {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }
  
  .project h1 {
    margin-top: 80px;
    color: black;
    font-size: 35px;
    text-decoration: underline;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .project img {
    width: 20%;
    border-radius: 10px;
    padding-bottom: 50px;
  }
  
  .project p {
    font-size: 40px;
    color: black;
  }
  
  .project svg {
    font-size: 80px;
    color: grey;
    padding: 20px;
  }

  .icons{
    justify-items: center;
    flex-direction: row;
  }