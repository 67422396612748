@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:wght@400&display=swap');


.App {
    text-align: center;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-family: "Inria Serif", serif;
    background-color: white;
}

body {
    padding: 0;
    margin: 0;
}